<template>
<div>
    <v-app-bar flat clipped-right app>
        <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <router-link style="cursor:pointer;" to="/">
                        <span v-on="on">
                            <v-img src="/img/logo_sun_n30.png" /></span>
                    </router-link>
                </template>
                <span>На головну</span>
            </v-tooltip>
        </v-toolbar-title>
                <!-- лого для телефона -->
        <v-toolbar-title v-if="$vuetify.breakpoint.xs">
            <router-link style="cursor:pointer;" to="/">
                <v-img src="/img/logo_sun_n30.png" width="230"/>
            </router-link>
        </v-toolbar-title>
    </v-app-bar>
    <CheckoutPage />
</div>
</template>

<script>
import CheckoutPage from '../components/CheckoutPage.vue'

export default {
    data: () => ({}),
    components: {
        CheckoutPage
    },
    methods: {},
    mounted() {}
}
</script>
